import { getActivePinia } from 'pinia'

import { useInfluencersStore } from '~/stores/influencers'
import { useUserStore } from '~/stores/user'

export async function getLandingPageInfluencers(
  locale: Locale['code'],
  fallbackLocale: Locale['code'],
): Promise<Record<string, number[]>> {
  const pinia = getActivePinia()

  if (useUserStore(pinia).IS_LOGGED_IN) return {}

  const influencersStore = useInfluencersStore(pinia)

  try {
    const genreToInfluencerRelation: Record<string, { id: number }[]> =
      await $coreFetch.$get(`/welcome/spotlight/`, {
        query: {
          lang: locale,
          fallback_lang: fallbackLocale,
        },
        timeout: 1500,
      })
    const genreToInfluencerIdRelation: Record<string, number[]> =
      Object.entries(genreToInfluencerRelation).reduce(
        (accumulator, [key, value]) => {
          accumulator[key] = value.map(
            (partialInfluencer) => partialInfluencer.id,
          )
          return accumulator
        },
        {} as Record<string, number[]>,
      )

    await influencersStore.FETCH_SET([
      ...Object.values(genreToInfluencerIdRelation).reduce(
        (accumulator, values) => {
          values.forEach((influencerId) => {
            accumulator.add(influencerId)
          })
          return accumulator
        },
        new Set<number>(),
      ),
    ])
    return genreToInfluencerIdRelation
  } catch (err) {
    return {}
  }
}
